.c-footer {
  .c-nav-footer ul {
    margin: 0;

    li {
      margin-right: 20px;
      color: $white;

      @media (max-width: 575px) {
        width: 100%;
        margin-right: 0;
      }

      a {
        color: $white;
        text-decoration: none;

        &:hover {
          color: $white;
          text-decoration: underline;
        }
      }
    }
  }

  .c-footer__logo img {
    width: 100px;
    height: auto;

    @media (max-width: 991px) {
      width: 120px;
    }
  }
}