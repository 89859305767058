body.is-open-sidebar {
  height: 100vh;
  overflow: hidden;
}

.c-sidebar {
  position: fixed;
  width: 100vw;
  height: auto;
  top: 0;
  bottom: 0;
  right: -100vw;
  transition: all .3s ease-in-out;
  padding-top: 150px;
  overflow-y: auto;
  z-index: 998;

  .is-open-sidebar & {
    transition: all .3s ease-in-out .1s;
    right: 0;
  }

  .c-sidebar-inner {
    position: relative;
    min-height: 100%;

    .c-nav-primary {
      li {
        padding: .375rem 0;
        text-align: center;

        a {
          color: $black;
          font-size: 1.5rem;
          padding: .25rem 0;
          position: relative;

          &::after {
            content: "";
            display: block;
            position: absolute;
            width: 2px;
            height: 0;
            left: -10px;
            top: 50%;
            transform: translateY(-50%);
            background-color: $black;
            transition: height .3s;
          }

          &:hover {
            text-decoration: none;

            &::after {
              height: 1.375rem;
            }
          }
        }

        &.active a::after {
          height: 1.375rem;
        }
      }
    }
  }
}