h1, h2, h3 {
  color: $black;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}

h1 {
  font-size: 3.25rem;
  line-height: 4rem;
  margin-bottom: 1rem;

  @media (max-width: 1199px) {
    font-size: 2.75rem;
    line-height: 3.5rem;
  }
  @media (max-width: 991px) {
    font-size: 2.5rem;
    line-height: 3.25rem;
  }
}

h2 {
  font-size: 2.5rem;
  line-height: 3rem;
  margin-bottom: 1.75rem;

  @media (max-width: 767px) {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 1rem;
  }
}

h3 {
  font-size: 1.375rem;
  line-height: 1.75rem;
  margin-bottom: 1.5rem;

  &.default {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.5625rem;
    line-height: 1.875rem;
    margin-bottom: 0;

    @media (max-width: 1199px) {
      font-size: 1.5rem;
      line-height: 1.75rem;
    }
    @media (max-width: 991px) {
      font-size: 1.375rem;
      line-height: 1.75rem;
    }
    @media (max-width: 767px) {
      font-size: 1.25rem;
      line-height: 1.5625rem;
    }
  }
}

p, span, li, a {
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-family: 'Roboto', 'Arial', sans-serif;
  color: $body;

  &.text-white a {
    color: $white;
  }
}

label {
  color: $black;
}

p a, section:not(.footer) li a {
  color: $primary;
  text-decoration: underline;

  &:hover {
    color: $primary;
    text-decoration: none;
  }
}

h1, h2, h3, p, span, li, a, div {
  @each $name, $color in $colors {
    &.text-#{$name} {
      color: $color !important;
    }
  }
}

div, section, aside {
  @each $name, $color in $colors {
    &.bg-#{$name} {
      background-color: $color !important;
    }
  }
}

html {
  scroll-behavior: smooth;

  main {
    overflow: hidden;
  }
}

.background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;

  &.background-overlay-gradient {
    background: $black;
    background: linear-gradient(0deg, rgba($black,1) 0%, rgba($black,0) 50%);
  }
  &.background-overlay-dark {
    background-color: rgba(0,0,0,0.5);
  }
  &.background-overlay-light {
    background-color: rgba(255,255,255,0.8);
  }
}

//:root {
//  --animate-delay: 0.5s;
//}
