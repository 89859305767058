.c-reservation {
  margin: 0;
  padding: 0;
  height: 0;
}

.c-contact {
  .contact {
    p {
      color: $black;
      margin-bottom: 25px;

      a {
        color: $black;

        &:hover {
          color: $black;
          text-decoration: none;
        }
      }
    }

    .socials {
      li {
        margin-right: 10px;


        a {
          color: $black;
          text-decoration: none;

          &:hover {
            color: $black;
            text-decoration: underline;
          }

          img {
            width: 2rem;
            height: 2rem;
            margin-right: 10px;
          }
        }
      }
    }
  }

  .reservation {

  }
}