form {
  .row {
    margin-bottom: 1rem;

    input[type=text], input[type=email], input[type=date], input[type=time], textarea.form-control {
      padding: 12px;
      font-size: 1rem;
      border: solid 1px transparent;
      border-radius: 0;
      background-color: $light-grey;
      box-shadow: none;

      &:focus, &:focus-visible {
        outline: none;
        border-color: $grey;
      }
    }

    input[type=text], input[type=email], input[type=date], input[type=time] {
      min-height: 50px;
    }
  }
}

.form-box {
  .status {
    margin-top: 10px;
    font-size: 1.125rem;
    color: $primary;
  }
}