.slick-slider {
  .slick-prev, .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    padding: 0;
    border: none;
    outline: none;
    background-color: $primary;
    cursor: pointer;
    z-index: 5;

    &::before {
      content: url("./icons/caret-right.svg");
      display: inline-block;
      vertical-align: middle;
      width: 20px;
      height: 20px;
    }
  }

  .slick-prev {
    left: 0;

    &::before {
      transform: rotate(180deg);
    }
  }
  .slick-next {
    right: 0;
  }

  .slick-track .slick-slide {
    max-width: 525px;

    @media (max-width: 575px) {
      max-width: 100vw;
    }

    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
  }
}
* { min-height:0 }