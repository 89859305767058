$primary: #A78B70;
$grey: #DDDDDD;
$light-grey: #F1F1F1;
$white: #FFFFFF;
$black: #181413;
$body: #484848;

$colors: (
        "primary": $primary,
        "grey": $grey,
        "light-grey": $light-grey,
        "white": $white,
        "black": $black,
        "body": $body,
);

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400&family=Montserrat:ital,wght@0,400;0,700;1,400&display=swap');