.vbox-content{
  width: 100%;
  height: calc(100% - 60px);
}

.vbox-content img{
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: transparent !important;
  box-shadow: none !important;
}