.c-hero {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  position: relative;
  height: 800px;

  @media (max-width: 767px) {
    height: 500px;

    h1 {
      margin-top: 5rem;
      font-size: 2.25rem;
      text-align: center;
      text-shadow: 0 0 5px rgba($black, .5);
    }
  }
  @media (max-width: 480px) {
    height: 400px;
  }

  .float-down {
    margin-bottom: -50px;

    @media (max-width: 767px) {
      margin-bottom: -140px;
    }
    @media (max-width: 480px) {
      margin-bottom: -250px;
    }
  }
}