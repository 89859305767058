.c-gallery {
  .slider {
    height: 350px;

    @media (max-width: 575px) {
      height: 275px;
    }

    .slide {
      position: relative;
      height: 350px;
      margin: 0 15px;

      @media (max-width: 575px) {
        height: 300px;
      }

      .fullscreen-icon {
        position: absolute;
        display: block;
        left: 10px;
        bottom: 10px;
        width: 25px;
        height: 25px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}