.c-cookie-policy, .c-privacy-policy {
  h2 {
    margin-bottom: 1.25rem;
  }
  h3 {
    margin-bottom: .75rem;
  }
}

// Cookie banner
#cookieConsent {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  display: none;
  z-index: 9999;
  box-shadow: 0 0 15px 5px rgba($black, .25);

  p, p a {
    font-size: .875rem;
    margin-bottom: 0;
  }

  p a:hover {
    color: $white;
    text-decoration: none;
  }

  .row > div {
    align-items: center;
    margin-bottom: 0 !important;

    &.buttons {
      a.btn {
        width: 190px;
        font-size: 17px;

        &:first-child {
          margin-right: 10px;
        }
      }

      @media (max-width: 991px) {
        flex-wrap: wrap;
        justify-content: flex-end;

        a.btn:first-child {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }

      @media (max-width: 575px) {
        text-align: center !important;
        justify-content: center;
        margin-top: 20px;

        a.btn {
          margin-right: 10px !important;
          margin-left: 10px !important;
          margin-bottom: 10px;
        }
      }
    }
  }
}

