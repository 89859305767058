.c-concept {
  @media (max-width: 767px) {
    margin-top: 100px;
  }
  @media (max-width: 480px) {
    margin-top: 225px;
  }

  &.extra-margin {
    @media (max-width: 370px) {
      margin-top: 320px;
    }
  }

  .image-wrapper {
    position: relative;

    img {
      position: relative;
      width: calc(100% - 50px);
      z-index: 5;
    }

    .image-block-right {
      position: absolute;
      background-color: $primary;
      left: 50px;
      top: 40px;
      width: calc(100% - 50px);
      height: 100%;
      z-index: 4;
    }
  }
}