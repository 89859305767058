@keyframes headerDown{
  0% {
    transform:translateX(0) translateY(-100%)
  } to {
    transform:translateX(0) translateY(0)
  }
}

.is-open-sidebar {
  .c-header {
    &.is-fixed {
      box-shadow: none;
    }

    .c-header__logo {
      img {
        filter: brightness(0);
      }
    }

    .c-hamburger span {
      background-color: black;
      box-shadow: none;
    }
  }
}

.c-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;

  &.is-fixed {
    position: fixed;
    transform: translateX(0) !important;
    -webkit-animation: headerDown 1s;
    animation: headerDown 1s;
    background-color: $white;
    box-shadow: 0 0 10px 2px rgba($black, .1);

    .c-header__logo {
      img {
        filter: brightness(0);
        width: 150px;

        @media (max-width: 991px) {
          width: 130px;
        }
        @media (max-width: 767px) {
          width: 105px;
        }
      }
    }

    .c-header__menu .c-nav-primary li a,
    .c-header__menu .c-nav-language a {
      color: $black;
      text-shadow: none;

      &::after {
        background-color: $black;
      }
    }

    .c-hamburger span {
      background-color: black;
      box-shadow: none;
    }
  }

  .c-header__logo img {
    width: 180px;

    @media (max-width: 991px) {
      width: 150px;
    }
    @media (max-width: 767px) {
      width: 125px;
    }
  }

  .c-header__menu {
    .c-nav-primary ul {
      margin-bottom: 0;

      li {
        margin-right: 2rem;

        a {
          color: $white;
          text-shadow: 0px 0px 5px rgba($black, .9);
          font-size: 1.25rem;
          padding: .25rem 0;
          position: relative;

          &::after {
            content: "";
            display: block;
            position: absolute;
            width: 0;
            height: 2px;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            background-color: $white;
            transition: width .3s;
          }

          &:hover {
            text-decoration: none;

            &::after {
              width: calc(100% - 20px);
            }
          }
        }

        &.active a::after {
          width: calc(100% - 20px);
        }
      }
    }

    .c-nav-language a {
      color: $white;
      text-shadow: 0px 0px 5px rgba($black, .9);
      font-size: 1.25rem;
      padding: .25rem 0;
      position: relative;

      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 0;
        height: 2px;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        background-color: $white;
        transition: width .3s;
      }

      &:hover {
        text-decoration: none;

        &::after {
          width: calc(100% - 15px);
        }
      }
    }
  }
}

.is-open-sidebar .c-header .c-header__menu .c-nav-language a {
  color: $black;
  text-shadow: none;

  &::after {
    background-color: $black;
  }
}

.c-header-background {
  width: 100%;
  height: 112px;

  @media (max-width: 991px) {
    height: 98px;
  }
  @media (max-width: 767px) {
    height: 88px;
  }
}

.c-hamburger {
  width: 30px;
  height: 22px;
  cursor: pointer;
  position: relative;
  display: none;

  @media (min-width: 992px) {
    margin-top: 5px;
  }
  @media (max-width: 991px) {
    display: inline-block;
  }

  span {
    display: block;
    position: absolute;
    left: 0;
    height: 3px;
    width: 100%;
    background-color: $white;
    box-shadow: 0 0 2px 1px rgba($black, .10);
    opacity: 1;
    right: 0;
    transition: all .25s ease-in-out 0s;
    transform: rotate(0deg);

    &:nth-child(1) {
      margin-top: 0;
    }

    &:nth-child(2) {
      margin-top: 10px;
      width: 100%;
      margin-left: auto;
    }

    &:nth-child(3) {
      margin-top: 20px;
    }
  }

  &.is-open span {
    &:nth-child(1) {
      transform: rotate(135deg);
      margin-top: 10px;
    }

    &:nth-child(2) {
      opacity: 0;
      right: -75px;
    }

    &:nth-child(3) {
      transform: rotate(-135deg);
      margin-top: 10px;
    }
  }
}