.c-steps {
  padding-top: 200px;

  @media (max-width: 767px) {
    padding-top: 150px;
  }

  .background-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .tabs {
    transform: translateY(-120px);

    @media (max-width: 767px) {
      transform: translateY(-80px);
    }

    .tab-headers {
      .tab-header {
        position: relative;
        text-decoration: none;
        color: $black;
        font-size: 1.5rem;
        line-height: 1.2;
        opacity: .5;
        transition: all .3s;

        &.active, &:hover {
          color: inherit;
          opacity: 1;
        }

        &::after {
          content: url("./icons/chevron-right.svg");
          position: absolute;
          display: block;
          right: 0;
          top: 3px;
          height: auto;
          width: 12px;
          transition: all .3s;
        }

        @media (max-width: 991px) {
          &.active::after {
            transform: rotate(90deg);
          }
        }
      }
    }

    .tab-contents {
      .tab-content {
        display: none;

        &.active {
          display: block;
        }

        .text {
          ul {
            padding-left: 18px;
            list-style-type: square;

            li {
              color: $black;
              padding-bottom: 2px;
            }
          }
        }

        .photos {
          .slider-small {
            height: 100px;

            .slide {
              height: 100px;
              max-width: 150px;
              margin: 0 10px;
            }
          }
        }
      }
    }
  }
}