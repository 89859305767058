.btn {
  padding: 12px 20px;
  border-radius: 0;
  font-size: 1rem;
  line-height: 1rem;
  border: solid 2px transparent;
  transition: all .2s;

  &.btn-primary {
    color: $white;
    background: $primary;

    &:hover, &:active {
      border-color: $primary !important;
      color: $primary !important;
      background: transparent !important;
    }
  }

  &.btn-white {
    color: $black;
    background: $white;

    &:hover, &:active {
      border-color: $white !important;
      color: $white !important;
      background: $primary !important;
    }
  }

  &.btn-link {
    color: $black;

    &:hover, &:active {
      color: $black !important;
      text-decoration: underline !important;
    }
  }
}